
.block-number {
  text-align: center;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 1px;
  text-transform: uppercase;

  &::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 8px;
    margin-bottom: 2px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #191919;
  }
}

.demo-section-boxed .main-content .section,
.demo-navbar-boxed .main-content .navbar,
.demo-header-boxed .main-content .header,
.demo-footer-boxed .main-content .footer {
  margin: 30px 20px 100px;
  border: 1px solid #f5f6f7;
  box-shadow: 0 0 15px rgba(0,0,0,0.05);
}

.demo-navbar-boxed .main-content .navbar {
  position: static;
}
